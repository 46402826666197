export function data() {
  return [
    {
      label: 'Propensity To Pay',
      value: this.creditStatus,
    },
    {
      label: 'Federal Poverty Level',
      value: `${this.fpl}%`,
    },
    {
      label: 'Available Credit',
      value: this.availableCredit,
    },
    {
      label: 'Estimated Household Income (Monthly)',
      value: this.estHouseholdIncome,
    },
    {
      label: 'Estimated Household Size',
      value: this.estHouseholdSize,
    },
    {
      label: 'Income Data Source',
      value: this.incomeDataSource,
    },
    {
      label: 'Recovery Score',
      value: this.creditScoreRecovery,
    },
    {
      label: 'Last Run',
      value: this.dateStr(this.runDate),
    },
  ];
}

import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

library.add(faExclamationCircle);

export const Status = {
  low: 'low',
  medium: 'medium',
  high: 'high',
};

export default {
  props: ['status', 'score'],
  computed: {
    variant() {
      switch (String(this.status).toLowerCase()) {
        case Status.low:
          // red
          return 'danger';
        case Status.medium:
          // yellow
          return 'warning';
        case Status.high:
          // green
          return 'success';
        default:
          return null;
      }
    },
    text() {
      switch (String(this.status).toLowerCase()) {
        case Status.low:
          // red
          return 'Low confidence of payment';
        case Status.medium:
          // yellow
          return 'Review details';
        case Status.high:
          // green
          return 'High confidence of payment';
        default:
          return null;
      }
    },
  },
};

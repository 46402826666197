import Widget from 'src/components/FullPatientData/PatientWidgetBody/Widget/Widget';
import SelfPayData from 'src/components/FullPatientData/PatientWidgetBody/SelfPay/SelfPayData/SelfPayData';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { mapGetters, mapActions, mapState } from 'vuex';
import { widgets } from 'src/constants/widgets';
import { permissionTypes } from 'src/constants/permissions';
import { GET_CREDIT } from 'src/store/actions/types';

// icon of the widget header
library.add(faUser);

export default {
  props: {
    loadedCallback: {
      type: Function,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
    Widget,
    SelfPayData
  },
  computed: {
    ...mapGetters(['applicant', 'hasPermission']),
    ...mapState({
      loading: state => state.applicant.credit.loading,
      retrieved: state => state.applicant.credit.retrieved,
      applicantCredit: state => state.applicant.credit.data,
      guarantorCredit: state => state.guarantor.credit.data,
    }),
    widgetType() {
      return widgets.selfPay.type;
    },
    // permission
    canView() {
      return this.hasPermission(permissionTypes.canViewApplicant);
    },
    // permission
    canRunSelfPay() {
      return this.hasPermission(permissionTypes.canRunSelfPay);
    },
    canViewSelfPay() {
      return this.hasPermission(permissionTypes.viewSelfPay);
    },
    // true if the user has not yet requested credit information
    notYetRequested() {
      return !this.applicantCredit.length && !this.guarantorCredit.length;
    },
    // true if not data is available for this patient (even after a search)
    noDataAvailable() {
      return (
        !this.notYetRequested &&
        this.applicantCredit.every(
          item =>
            item.creditScore === null &&
            item.dti === null &&
            item.fpl === null &&
            item.residualIncome === null &&
            item.availableCredit === null &&
            item.creditStatus === null &&
            item.estHouseholdIncome === null &&
            item.estHouseholdSize === null &&
            item.creditScoreRecovery === null &&
            item.creditScoreFICO === null &&
            item.incomeDataSource === null &&
            item.suggestion === null
        ) &&
        this.guarantorCredit.every(
          item =>
            item.creditScore === null &&
            item.dti === null &&
            item.fpl === null &&
            item.residualIncome === null &&
            item.availableCredit === null &&
            item.creditStatus === null &&
            item.estHouseholdIncome === null &&
            item.estHouseholdSize === null &&
            item.creditScoreRecovery === null &&
            item.creditScoreFICO === null &&
            item.incomeDataSource === null &&
            item.suggestion === null
        )
      );
    },
  },
  methods: {
    ...mapActions([GET_CREDIT]),
    async runSelfPayTest() {
      this[GET_CREDIT]({ runSelfPayAnalyzer: true });
    },
  },
  watch: {
    // TODO: refactor this into the widget component,
    // when ALL widgets actually use that component, to be DRY
    loading: {
      immediate: true,
      handler(current, previous) {
        // make sure the DOM is updated before dispatching this action
        // because we need the widget to have its full dimensions after
        // loading the data and rendering them...
        this.$nextTick(function() {
          if (current !== previous) {
            this.loadedCallback(!current);
          }
        });
      },
    },
  },
  beforeDestroy() {
    this.loadedCallback(false);
  },
  created() {
    if (!this.retrieved) {
      this[GET_CREDIT]();
    }
  },
};

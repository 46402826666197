import { stripEmptyValues } from 'src/utils/stripEmptyValues';

export function collectionAccounts() {
  return {
    isExpanded: false,
    isDisabled: !this.collectionAccounts.length,
    label: `Collection Accounts (${this.collectionAccounts.length})`,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/SelfPay/SelfPayData/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: this.collectionAccounts.map(item => ({
        id: item.collectionAccountId,
        fields: stripEmptyValues([
          {
            label: 'Portfolio Type',
            value: item.portfolioType,
          },
          {
            label: 'ECOA Designator',
            value: item.ecoaDesignator,
          },
          {
            label: 'Date Opened',
            value: item.dateOpened,
          },
          {
            label: 'Date Closed',
            value: item.dateClosed,
          },
          {
            label: 'Current Balance',
            value: item.currentBalance,
          },
          {
            label: 'Past Due',
            value: item.pastDue,
          },
          {
            label: 'Date First Delinquent',
            value: item.dateFirstDelinquent,
          },
          {
            label: 'Collection Agency',
            value: item.collectionAgencyName,
          },
          {
            label: 'Creditor Name',
            value: item.creditorName,
          },
          {
            label: 'Account Number',
            value: item.accountNumber,
          },
          {
            label: 'Closed Indicator',
            value: item.closedIndicator,
          },
          {
            label: 'Date Effective',
            value: item.dateEffective,
          },
          {
            label: 'Date Paid Out',
            value: item.datePaidOut,
          },
          {
            label: 'Account Rating',
            value: item.accountRating,
          },
          {
            label: 'Update Method',
            value: item.updateMethod,
          },
          {
            label: 'Date Reported',
            value: item.dateReported,
          },
          {
            label: 'C.A. Kind of Business',
            value: item.collectionAgencyKob,
          },
          {
            label: 'Original Balance',
            value: item.originalBalance,
          },
        ]),
      })),
    },
  };
}

import { stripEmptyValues } from 'src/utils/stripEmptyValues';
import { buildAddress } from 'src/utils/buildAddress';

export function employmentRecords() {
  return {
    isExpanded: false,
    isDisabled: !this.employment.length,
    label: `Employment Records (${this.employment.length})`,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/SelfPay/SelfPayData/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: this.employment.map(item => ({
        id: item.employmentId,
        fields: stripEmptyValues([
          {
            label: 'Employer',
            value: item.employer,
          },
          {
            label: 'Date Hired',
            value: this.dateStr(item.dateHired),
          },
          {
            label: 'Income',
            value: item.income,
          },
          {
            label: 'Employer Address',
            value: buildAddress({
              address1: item.address,
              city: item.city,
              state: item.state,
              zip: item.zip,
            }),
          },
          {
            label: 'Date Added to File',
            value: item.dateAddedToFile,
          },
          {
            label: 'Occupation',
            value: item.occupation,
          },
          {
            label: 'Date Terminated',
            value: item.dateTerminated,
          },
          {
            label: 'Employer Phone',
            value: item.phone,
          },
          {
            label: 'Date Last Verified',
            value: this.dateStr(item.dateLastVerified),
          },
        ]),
      })),
    },
  };
}

import { stripEmptyValues } from 'src/utils/stripEmptyValues';

export function tradeAccounts() {
  return {
    isExpanded: false,
    isDisabled: !this.tradeAccounts.length,
    label: `Trade Accounts (${this.tradeAccounts.length})`,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/SelfPay/SelfPayData/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: this.tradeAccounts.map(item => ({
        id: item.tradeAccountId,
        fields: stripEmptyValues([
          {
            label: 'Portfolio Type',
            value: item.portfolioType,
          },
          {
            label: 'ECOA Designator',
            value: item.ecoaDesignator,
          },
          {
            label: 'Date Opened',
            value: item.dateOpened,
          },
          {
            label: 'Date Closed',
            value: item.dateClosed,
          },
          {
            label: 'Current Balance',
            value: item.currentBalance,
          },
          {
            label: 'High Credit',
            value: item.highCredit,
          },
          {
            label: 'Past Due',
            value: item.pastDue,
          },
          {
            label: 'Miscellaneous',
            value: item.miscellaneous,
          },
          {
            label: 'Date of Last Payment',
            value: item.dateLastPayment,
          },
          {
            label: 'Date Verified',
            value: item.dateVerified,
          },
          {
            label: 'Payment Amount',
            value: item.paymentAmount,
          },
          {
            label: 'Payment Status',
            value: item.paymentStatus,
          },
          {
            label: '# Times 30 Days Late',
            value: item.numTimes30DaysLate,
          },
          {
            label: '# Times 60 Days Late',
            value: item.numTimes60DaysLate,
          },
          {
            label: '# Times 90 Days Late',
            value: item.numTimes90DaysLate,
          },
          {
            label: 'Account Number',
            value: item.accountNumber,
          },
          {
            label: 'Closed Indicator',
            value: item.closedIndicator,
          },
          {
            label: 'Date Effective',
            value: item.dateEffective,
          },
          {
            label: 'Date Paid Out',
            value: item.datePaidOut,
          },
          {
            label: 'Account Rating',
            value: item.accountRating,
          },
          {
            label: 'Credit Limit',
            value: item.creditLimit,
          },
          {
            label: 'Update Method',
            value: item.updateMethod,
          },
          {
            label: 'Date of Last Activity',
            value: item.dateLastActivity,
          },
          {
            label: 'Date Reported',
            value: item.dateReported,
          },
          {
            label: 'Date of Max. Delinquency',
            value: item.dateMaxDelinquency,
          },
          {
            label: 'Payment Frequency',
            value: item.paymentFrequency,
          },
          {
            label: 'Consumer Comment',
            value: item.consumerComment,
          },
        ]),
      })),
    },
  };
}

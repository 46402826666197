import CreditStatus from 'src/components/FullPatientData/PatientWidgetBody/SelfPay/SelfPayData/CreditStatus/CreditStatus';
import AccordionGroup from 'src/components/FullPatientData/PatientWidgetBody/AccordionGroup/AccordionGroup';
import DefList from 'src/components/FullPatientData/PatientWidgetBody/DefList/DefList';
import { data } from './computed/data';
import { tradeAccounts } from './computed/tradeAccounts';
import { collectionAccounts } from './computed/collectionAccounts';
import { employmentRecords } from './computed/employmentRecords';

export default {
  props: [
    'creditStatus',
    'fpl',
    'availableCredit',
    'estHouseholdIncome',
    'estHouseholdSize',
    'incomeDataSource',
    'creditScoreRecovery',
    'runDate',
    'tradeAccounts',
    'employment',
    'collectionAccounts',
  ],
  components: {
    CreditStatus,
    AccordionGroup,
    DefList,
  },
  computed: {
    // flat data to be rendered in a DL on the top
    data() {
      return data.call(this);
    },
    /**
     * object with accordion groups, in case we have multiple
     * @returns {object}
     */
    accordionGroups() {
      return {
        // extra data
        extra: {
          tradeAccounts: tradeAccounts.call(this),
          collectionAccounts: collectionAccounts.call(this),
          employmentRecords: employmentRecords.call(this),
        },
      };
    },
  },
};
